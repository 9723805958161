// dependencies
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { format } from 'date-fns'

// components
import Divider from './Divider';

// styles
import './ScheduleEvents.css';

// Config
import Config from '../../Config';

const ScheduleEvents = () => {
  const [events, setEvents] = useState([]);
  const [currentType, setCurrentType] = useState('upcoming');

  useEffect(()=>{
    axios.get(`${Config.webserver}/schedule/?scheduleType=${currentType}`).then(response => {
      const schedule = response.data;
     setEvents(schedule);
    });
  },[currentType])

  return (
    <div id="schedule-events-container">
      <div id="scheduleTypeContainer">
        <div className={`scheduleEventsTitle ${currentType==='upcoming' ? 'currentType': ''}`} onClick={()=>{setCurrentType('upcoming')}}> Upcoming</div>
        <div className={`scheduleEventsTitle ${currentType==='past' ? 'currentType': ''}`} onClick={()=>{setCurrentType('past')}}> Past</div>
      </div>
      {events.map((event, ii) => (
          <div id="events-container">
          <div className="scheduleEvent">
            <div className="eventTitle">{event.eventName}</div>
            <div className="eventDate">{format(new Date(event.date), 'MMMM do yyyy @ h:mm a')}</div>
            <div className="eventLocation">{`${event.location}`}</div>
            <div className="eventDescription">
              {event.description}
            </div>
          </div>
          {(ii !== events.length -1) ?
            <div className="eventDivider">
              <Divider />
            </div>:
            null
          }
        </div>
      ))}
      {!events.length ?
      <div className="comingSoon">Coming soon!</div>:
      null
      }
    </div>
  );
};

export default ScheduleEvents;

import React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={350}
      height={63}
      viewBox="0 0 1309 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 31l282.941 10.2L555 48V14l-272.059 6.8L0 31zM1309 31l-282.94 10.2L754 48V14l272.06 6.8L1309 31z"
        fill="#B49730"
      />
      <ellipse
        rx={31}
        ry={31.5}
        transform="matrix(1 0 0 -1 655 31.5)"
        fill="#B49730"
      />
      <circle r={22.5} transform="matrix(1 0 0 -1 654.5 31.5)" fill="#fff" />
      <path
        d="M555 31l33.77 11.333L613.908 48 628 31l-14.092-17-25.138 5.667L555 31zM754 31l-33.77 11.333L695.092 48 681 31l14.092-17 25.138 5.667L754 31z"
        fill="#B49730"
      />
    </svg>
  );
}

export default SvgComponent;

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import{ init } from 'emailjs-com';
import { StyleRoot } from 'radium';

import { Home } from './containers/Home/Home';
import { About } from './containers/About/About';
import { Contact } from './containers/Contact/Contact';
import { Gallery } from './containers/Gallery/Gallery';
import { Schedule } from './containers/Schedule/Schedule';
import { Studio } from './containers/Studio/Studio';
import { Music } from './containers/Music/Music';
import {Edit} from './containers/Edit/Edit';

import ScrollToTop from './components/ScrollToTop';

init("user_J2fUVT1gF8Ve9rHGK2nOf");

class App extends React.Component {
  render() {
    return (
      // <StyleRoot>
      //   <BrowserRouter>
      //     <ScrollToTop>
      //       <Switch>
      //         <Route path="/" exact component={Home} />
      //         <Route path="/about" exact component={About} />
      //         <Route path="/contact" exact component={Contact} />
      //         <Route path="/gallery" exact component={Gallery} />
      //         <Route path="/schedule" exact component={Schedule} />
      //         <Route path="/studio" exact component={Studio} />
      //         <Route path="/music" exact component={Music} />
      //         <Route path="/edit" exact component={Edit} />
      //       </Switch>
      //     </ScrollToTop>
      //   </BrowserRouter>
      // </StyleRoot>
      <div>Under Construction</div>
    );
  }
}

export default App;

import React from 'react';
import { NavLink } from 'react-router-dom';
import './HomeNav.css';
import schedule from '../../assets/images/carnegie.jpeg';
import music from '../../assets/images/schedule.jpg';
import studio from '../../assets/images/studio.jpg';

const homeNav = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-4 home-nav-div">
        <img className="home-nav-photo" src={schedule} alt="carnegie" />
        <NavLink to="/schedule">
          <div className="after">
            <div className="nav-label">Schedule</div>
            <div className="nav-description">Upcoming events</div>
          </div>
        </NavLink>
      </div>
      <div className="col-lg-4 home-nav-div">
        <img className="home-nav-photo" src={music} alt="schedule" />
        <NavLink to="/music">
          <div className="after">
            <div className="nav-label">Music</div>
            <div className="nav-description">Latest recordings</div>
          </div>
        </NavLink>
      </div>
      <div className="col-lg-4 home-nav-div">
        <img className="home-nav-photo" src={studio} alt="studio" />
        <NavLink to="/studio">
          <div className="after">
            <div className="nav-label">Studio</div>
            <div className="nav-description">Enroll now</div>
          </div>
        </NavLink>
      </div>
    </div>
  </div>
);

export default homeNav;

import React from 'react';
import './StudioForm.css';
import axios from 'axios';

// config
import Config from '../../../Config';

class StudioForm extends React.Component {
  state={
    parentName: '',
    studentName: '',
    studentBirthdate: '',
    email: '',
    additionalInfo: '',
    instrument: '',
    displaySentMessage: false,
  }
  render() {
    const handleInputChange = (e, inputField) => {
      this.setState({
        [inputField] : e.target.value
      })
    }

    const handleSubmit = (e) => {
      const{parentName, studentName, studentBirthdate, email, additionalInfo, instrument} = this.state;

     e.preventDefault();
     console.log(this.state)

     axios.post(`${Config.webserver}/studio-forms`, {
      parentName,
      studentName,
      studentBirthdate,
      email,
      additionalInfo,
      instrument
    })

    .then((response) => {
      if(response.status === 200){
        this.setState({
          displaySentMessage: true
        })
        
      }
    }, (error) => {
      console.log(error);
    });
    }

    return (
      <div id="form-container">
        {this.state.displaySentMessage ?
       <div className="email-recieved-message">
       Message Received! We will get back to you as soon as we can.
     </div> : 
      <div>
      <div className="page-header">
        <h2>Student Information</h2>
        <p className="lead">
          *Please submit a separate form for each student.
        </p>
      </div>
      <form id="studio-form" onSubmit={(e) => handleSubmit(e)}
      >
        <div className="form-group">
          <label htmlFor="parent-name">Parent Name</label>
          <input
            type="text"
            className="form-control shadow-none"
            id="parent-name"
            placeholder="First and Last"
            onChange={(e) => handleInputChange(e, 'parentName')}
          />
        </div>
        <div className="form-group">
          <label htmlFor="parent-name">Student Name</label>
          <input
            type="text"
            className="form-control shadow-none"
            id="student-name"
            placeholder="First and Last"
            onChange={(e) => handleInputChange(e, 'studentName')}
          />
        </div>
        <div className="form-group">
          <label htmlFor="student-birthdate">Student Birthdate</label>
          <input
            type="date"
            className="form-control shadow-none"
            id="student-birthdate"
            onChange={(e) => handleInputChange(e, 'studentBirthdate')}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            className="form-control shadow-none"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            onChange={(e) => handleInputChange(e, 'email')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="student-information">Additional Info</label>
          <br></br>
          <small>
            *Please include student's musical experience and available
            times.
          </small>
          <textarea
            className="form-control shadow-none"
            id="student-information"
            rows="3"
            onChange={(e) => handleInputChange(e, 'additionalInfo')}
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="owns-instrument">
            Do you have your instrument ready?
          </label>
          <select id="owns-instrument" className="form-control shadow-none" onChange={(e) => handleInputChange(e, 'instrument')}>
            <option></option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <button
          type="submit"
          className="btn btn-default"
          id="studio-submit"
        >
          Submit
        </button>
      </form>
      </div>
      }
      </div>
    );
  }
}

export default StudioForm;

import {useState, useEffect} from 'react';
import {projectFirestore} from '../firebase/config';

const useFirestore = (collection) => {
  const [docs, setDocs] = useState([]);
  const [fetchingDocs, setFetchingDocs] = useState(true);

  useEffect(() => {
    const unsub = projectFirestore.collection(collection)
      .onSnapshot((snap) => {
        let documents = [];
        snap.forEach(doc => {
          documents.push({...doc.data(), id: doc.id})
        });
        setDocs(documents)
        setFetchingDocs(false);
      });

      return() => unsub();
  },[collection])

  return {docs, fetchingDocs}
}

export default useFirestore;
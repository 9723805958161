import React from 'react';
import Layout from '../../components/Layout/Layout';
import musicTitle from '../../assets/images/music.png';
import './Music.css';
import videoCover from '../../assets/videoCoverPhotos/Prisme.png'
import videoCover2 from '../../assets/videoCoverPhotos/Nutcracker.png'
import videoCover3 from '../../assets/videoCoverPhotos/Rach.png'
import videoCover4 from '../../assets/videoCoverPhotos/saint-saens.png'
import YouTube from 'react-youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'

export class Music extends React.Component {
  state = {
    videos: [],
    selectedVideo: '',
  };

  handlePlayVideo = (e, videoID) => {
    e.preventDefault();
    this.setState({ selectedVideo: videoID });
  };

  handleExitVideo = () => {
    this.setState({selectedVideo: ''})
  }
  _onReady = (event) => {
    event.target.playVideo();
  }

  render() {
    return (
      <Layout>
         {this.state.selectedVideo ? (
          <div className="selected-video" onClick={this.handleExitVideo}>
            <YouTube videoId={this.state.selectedVideo} id="music-video" onReady={this._onReady} />
          </div>
        ) : null}
        <img id="music-font" src={musicTitle} alt="music" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 content">
              <div id="music-videos-container">
                <div className="musicVideoContainer">
                  <div className="videoPreviewContainer" onClick={e => this.handlePlayVideo(e, 'd0LyrNVZDek' )}>
                    <div className="videoPreviewOverlay"></div>
                    <div className="playIcon">
                      <FontAwesomeIcon icon={faPlayCircle}/>
                    </div>
                    <img className="gallery-photo" src={videoCover} alt={"prisme"} />
                  </div>
                  <div className="videoLabel">Boutry- Prisme</div>
                  <div className="videoDescription">
                    Piece for bassoon and piano. Performed in livestreamed recital with David Young. 
                  </div>
                </div>
                <div className="musicVideoContainer">
                  <div className="videoPreviewContainer" onClick={e => this.handlePlayVideo(e, 'KbPWclan4No' )}>
                    <div className="videoPreviewOverlay"></div>
                    <div className="playIcon">
                      <FontAwesomeIcon icon={faPlayCircle}/>
                    </div>
                    <img className="gallery-photo" src={videoCover4} alt={"saint-saens"} />
                  </div>
                  <div className="videoLabel">Saint-Saens - Sonata for Bassoon and Piano </div>
                  <div className="videoDescription">
                    Sonata for Bassoon and Piano, Performed in livestreamed recital with David Young. 
                  </div>
                </div>
                <div className="musicVideoContainer">
                  <div className="videoPreviewContainer" onClick={e => this.handlePlayVideo(e, 'mHHw88BBuk')}>
                    <div className="videoPreviewOverlay"></div>
                    <div className="playIcon">
                      <FontAwesomeIcon icon={faPlayCircle}/>
                    </div>
                    <img className="gallery-photo" src={videoCover2} alt={"nutcracker"} />
                  </div>
                  <div className="videoLabel">Cat Affairs</div>
                  <div className="videoDescription">
                  Cat Affairs by Anna Baadsvik, performed at Baylor University.
                  </div>
                </div>
                <div className="musicVideoContainer">
                  <div className="videoPreviewContainer" onClick={e => this.handlePlayVideo(e, 'pxAUSFWHIyM')}>
                    <div className="videoPreviewOverlay"></div>
                    <div className="playIcon">
                      <FontAwesomeIcon icon={faPlayCircle}/>
                    </div>
                    <img className="gallery-photo" src={videoCover3} alt={"rach"} />
                  </div>
                  <div className="videoLabel">Rachmaninoff- 12 Songs, Op. 21, No. 12</div>
                  <div className="videoDescription">
                  Performance with Darius Morton at the Collaborative Piano Institue.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    );
  }
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';

const footer = () => (
  <div className="footer-container" style={{ marginTop: '15vh' }}>
    <div>
      <ul id="footer-nav">
        <NavLink to="/schedule" className="footer-li">
          <li>SCHEDULE</li>
        </NavLink>
        <NavLink to="/gallery" className="footer-li">
          <li>GALLERY</li>
        </NavLink>
        <NavLink to="/music" className="footer-li">
          <li>MUSIC</li>
        </NavLink>
        <NavLink to="/" className="footer-li">
          <li>HOME</li>
        </NavLink>
        <NavLink to="/about" className="footer-li">
          <li>ABOUT</li>
        </NavLink>
        <NavLink to="/studio" className="footer-li">
          <li>STUDIO</li>
        </NavLink>
        <NavLink to="/contact" className="footer-li">
          <li>CONTACT</li>
        </NavLink>
      </ul>
    </div>
    <div id="footer-bottom">
      <div id="social-media">
        <img className="icon" src={facebook} alt="facebook" />
        <img className="icon" src={instagram} alt="instagram" />
      </div>
      <div></div>
    </div>
  </div>
);

export default footer;

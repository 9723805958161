import React from 'react';
import Layout from '../../components/Layout/Layout';
import StudioForm from './Studio-Form/StudioForm';
import StudioInfo from './Studio-Info/StudioInfo';
import studioFont from '../../assets/images/studio-font.jpg';
import './Studio.css';

export class Studio extends React.Component {
  render() {
    return (
      <Layout>
        <div className="container">
          <div className="row">
            <img id="studio-font" src={studioFont} alt="studio-font" />
          </div>
          <div className="row">
            <div className="col-xl-7">
              <StudioInfo />
            </div>
            <div className="col-xl-5">
              <StudioForm />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

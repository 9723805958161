import React from 'react';
import './Navbar.css';
import { NavLink, withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo-1.png';
import Radium from 'radium';

class Navbar extends React.Component {
  state = {
    backgroundColor: '',
    homePage: true,
    showNavDetails: false,
  };

  componentDidMount() {
    if (this.props.location.pathname === '/') {
      this.setState({
        homePage: true,
        backgroundColor: '#131313a7',
        showNavDetails: true,
      });
    } else if (this.props.location.pathname !== '/') {
      this.setState({ homePage: false, backgroundColor: '#212121' });
    }
  }

  toggleMobileNav = () => {
    this.setState({ showNavDetails: !this.state.showNavDetails });
  };

  render() {
    const activeStyle = {
      color: '#bc9152',
      fontWeight: 'bolder',
      fontSize: '2.1em',
    };
    const mobileNavStyle = {
      background: `${this.state.backgroundColor}`,
      '@media only screen and (min-width: 769px)': {
        display: 'none',
      },
    };

    const desktopNavStyle = {
      '@media only screen and (max-width: 769px)': {
        display: 'none',
      },
    };
    return (
      <>
        <div
          id="mobile-nav-container"
          style={mobileNavStyle}
          onClick={this.toggleMobileNav}
        >
          <img id="nav-logo" src={logo} alt="logo" />

          <div id="toggle">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <nav style={this.state.showNavDetails ? {} : desktopNavStyle}>
          <div
            id="navbar-container"
            style={{ backgroundColor: this.state.backgroundColor }}
          >
            <ul id="navbar-ul">
              <li></li>
              <li>
                <NavLink to="/schedule" activeStyle={activeStyle}>
                  SCHEDULE
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" activeStyle={activeStyle}>
                  GALLERY
                </NavLink>
              </li>
              <li>
                <NavLink to="/music" activeStyle={activeStyle}>
                  MUSIC
                </NavLink>
              </li>
              <li>
                <NavLink to="/" activeStyle={activeStyle}>
                  <div id="logo-container">
                    <img id="main-logo" src={logo} alt="logo" />
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" activeStyle={activeStyle}>
                  ABOUT
                </NavLink>
              </li>
              <li>
                <NavLink to="/studio" activeStyle={activeStyle}>
                  STUDIO
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeStyle={activeStyle}>
                  CONTACT
                </NavLink>
              </li>
              <li></li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default withRouter(Radium(Navbar));

import React from 'react';
import alexImg from '../../assets/images/alex.png';
import alexMobileImg from '../../assets/images/alex-mobile.jpeg';
import './Bio.css';
import MediaQuery from 'react-responsive';

const bio = () => (
  <div className="container">
    <div className="row">
      <div className=" col-lg-12 content">
        <MediaQuery maxDeviceWidth={768}>
          <img id="alex-img" src={alexMobileImg} alt="alex" />
        </MediaQuery>
        <MediaQuery minDeviceWidth={769}>
          <img id="alex-img" src={alexImg} alt="alex" />
        </MediaQuery>

        <div>
          <p>
            <div id="bio-name">Alexander Kostadinov</div>
            was born in Varna, Bulgaria and began his formative musical training
            at age four. Alex earned his bachelor’s and master’s degrees in
            music performance from Baylor University where he studied with Prof.
            Krassimira Jordan and Dr. Kae Hosoda-Ayer. During his time at Baylor
            University, he collaborated over 400 recitals with students, guest
            artists, and professors. He is currently a Doctoral student at the
            University of Maryland, where he studies under Prof. Rita Sloan. He
            is currently the pianist for the Maryland Opera Studio as well as
            the music minister at Hope Lutheran Church.
          </p>
        </div>

        <p>
          Throughout the years, Alex has won several prestigious international
          competitions including: Robert McCoy Award for Collaborative Piano
          concerto competition at the University of Maryland for best
          accompaniment, First prize at the Joseph Dichler Piano Competition in
          Vienna, Grand Prix at the Amici del Pianoforte Competition in Venice,
          and Grand Prix at the Competition for Slavic Music in Moscow. Alex has
          also received several awards during his music education including: the
          Dean’s Award for Outstanding Performance from Baylor University, the
          Outstanding Undergraduate Performer in Piano award, and a special
          award from the mayor of Varna, Bulgaria, for highest music
          achievements. He has performed with orchestras including: Varna State
          Orchestra, Sofia Soloists, and Baylor Symphony among others. In 2018,
          Alex was invited to go on a piano duo tour to Hong Kong and Malaysia,
          and was invited to be a collaborative pianist at the 2019 and the 2020
          National Trumpet Conference. Alex has been invited to participate in
          festivals such as Aspen (2019, 2020), and the Collaborative Piano
          Institute where he has worked with renowned coaches and instructors
          such as Martin Katz, Michael Baitzer, Kathleen Kelly, Dr. Jean Barr.
          He has been a collaborative pianist to artists such as John Romero,
          Mark Guilford, Joey Grimmer, Wiff Rudd. He was also invited to be the
          pianist and coach at the vocal summer academy “Music in the Marche” in
          Mondavio, Italy where he coached singers in Russian. Alex has
          performed in venues such as Carnegie hall, Rachmaninov hall, Bolshoi
          Theater and many others through the years as a solo and collaborative
          pianist.
        </p>
      </div>
    </div>
  </div>
);

export default bio;

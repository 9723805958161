import React from 'react';
import pianoVideo from '../../assets/videos/alex-video.mp4';
import Nav from '../Navbar/Navbar';
import './Video.css';

const video = () => (
  <div id="video-container">
    <video autoPlay loop muted playsInline>
      <source src={pianoVideo} type="video/mp4" />
    </video>

    <div id="video-nav">
      <Nav />
    </div>
  </div>
);

export default video;

import React from 'react';
import Layout from '../../components/Layout/Layout';
import scheduleTitle from '../../assets/images/schedule.png';
import './Schedule.css';
import ScheduleEvents from '../../components/ScheduleEvents/ScheduleEvents';

export class Schedule extends React.Component {
  render() {
    return (
      <Layout>
        <img id="schedule-font" src={scheduleTitle} alt="schedule" />
        <ScheduleEvents />
      </Layout>
    );
  }
}

import React from 'react';
import './StudioInfo.css';
import studioRecital from '../../../assets/images/studio-recital.jpg';

class StudioInfo extends React.Component {
  render() {
    return (
      <div id="studio-info-container">
        <h2 id="studio-title">Teaching Philosophy</h2>
        <img id="studio" src={studioRecital} alt="studio" />
        <div id="studio-description">
          <p style={{ textAlign: 'center' }}>
            Alex believes that anyone, regardless of their age or background,
            can learn music. He is an enthusiastic and fun teacher who enjoys
            working with students of all ages and creating a connection with
            each of his students to get to know their personalities and their
            goals. Having eight years of teaching experience, he has developed
            unique ways to learn music that make lessons enjoyable and
            effective. He believes music lessons should be fun and not
            intimidating and the purpose of the lessons are to help students
            achieve their goals in music whether it be playing classical, jazz,
            pop, or any style. By incorporating music theory, ear training, and
            some music history in lessons, students will become well-rounded
            musicians that have a better overall understanding of music.
          </p>
        </div>
      </div>
    );
  }
}

export default StudioInfo;

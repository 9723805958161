import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const layout = props => (
  <>
    <Navbar />
    <main>{props.children}</main>
    <Footer />
  </>
);

export default layout;

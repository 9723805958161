import React from 'react';
import Layout from '../../components/Layout/Layout';
import Bio from '../../components/Bio/Bio';
import bioFont from '../../assets/images/bio-font.jpg';
import './About.css';

export class About extends React.Component {
  render() {
    return (
      <Layout>
        <img id="bio-font" src={bioFont} alt="bio" />
        <Bio />
      </Layout>
    );
  }
}

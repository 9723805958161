import React from 'react';
import Video from '../../components/Video/Video';
import HomeAbout from '../../components/Home-About/HomeAbout';
import HomeNav from '../../components/Home-Nav/HomeNav';
import HomeExplore from '../../components/Home-Explore/HomeExplore';
import Footer from '../../components/Footer/Footer';
export class Home extends React.Component {
  render() {
    return (
      <>
        <Video />
        <HomeAbout />
        <HomeNav />
        <HomeExplore />
        <Footer />
      </>
    );
  }
}

/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect, useState} from 'react';
import './HomeExplore.css';
import exploreFont from '../../assets/images/explore-font.jpg';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import { format } from 'date-fns'
import useFirestore from '../../hooks/useFirestore';

// config
import Config from '../../Config';

const HomeExplore = () => {

  const [events, setEvents] = useState([]);

  const {docs} = useFirestore('images');

  const carouselImages = docs.slice(0,5);

  const eventImages = [
'https://images.pexels.com/photos/3104234/pexels-photo-3104234.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260', 'https://cdn.mos.cms.futurecdn.net/5PMe5hr8tjSS9Nq5d6Cebe.jpg', 'https://chuckdegroat.net/wp-content/uploads/2018/05/Black-and-White-Wallpaper-28-1920x1200.jpg', 'https://images.pexels.com/photos/748626/pexels-photo-748626.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
  ]

  useEffect(()=>{
    axios.get(`${Config.webserver}/schedule/?scheduleType=all`).then(response => {
      const schedule = response.data;
      setEvents(schedule);
    })
  },[])

  return(
  <div>
    <img id="explore-font" src={exploreFont} alt="explore" />
    <div id="carousel">
      <Carousel>
        {carouselImages.map(image => (
           <Carousel.Item>
           <img
             className="d-block w-100"
             src={image.url}
             alt={image.createdAt}
           />
           {/* <Carousel.Caption>
             <h3>First slide label</h3>
             <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
           </Carousel.Caption> */}
         </Carousel.Item>
        ))}
      </Carousel>
    </div>

    <div id="home-events">
      {events.map((event,ii) => {
        const eventMonth = format(new Date(event.date), 'MMM');
        const eventDate = format(new Date(event.date), 'dd');
        const eventTime = format(new Date(event.date), 'h:mm a');
        const eventName = event.eventName;
        const eventAddress = event.location;
        return(
         <div className="home-event" key={event._id}>
         <img src={eventImages[ii]} alt={eventImages[ii]}/>
         <div className="home-event-date">
           <span className="home-event-month">{eventMonth}</span>
           <span className="home-event-day">{eventDate}</span>
         </div>
         <div className="home-event-details">
           <h4>{eventName}</h4>
           <h5>@ {eventTime}</h5>
           <h5>{eventAddress}</h5>
         </div>
       </div>
        )
})}
     

    </div>
  </div>
  );
};
export default HomeExplore;

import React from 'react';
import Layout from '../../components/Layout/Layout';
import contactTitle from '../../assets/images/contact.png';
import './Contact.css';
import axios from 'axios';
import contactPageImg from '../../assets/images/contact-page-2.png';

// config
import Config from '../../Config';

export class Contact extends React.Component {
  state = {
    name: '',
    organization: '',
    email:'',
    phone: '',
    message:'',
    displaySentMessage: false,
  }
  render() {
    const handleInputChange = (e, inputField) => {
      this.setState({
        [inputField] : e.target.value
      })
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      const {name, organization, email, phone, message} = this.state;

      axios.post(`${Config.webserver}/contact`, {
        name,
        organization,
        email,
        phone,
        message,
      })
  
      .then((response) => {
        if(response.status === 200){
          this.setState({
            displaySentMessage: true
          })
           // reset state
          this.setState({
            name: '',
            organization: '',
            message: '',
            phone: '',
            email: ''
          })
        }
      }, (error) => {
        console.log(error);
      });
    }

    return (
      <Layout>
        <img id="contact-font" src={contactTitle} alt="contact" />
        <div className="container">
          <div className="row">
          <div className="col-xl-5">
            <img id="contact-img" alt="alex-img" src={contactPageImg}></img>
          </div>
          {
            this.state.displaySentMessage ? (
              <div className="col-xl-7 email-recieved-message">
                Message Received! We will get back to you as soon as we can.
              </div>
            ) :
            <div id="form-container" className="col-xl-7">
            <div id="contact-form-header">
              <h2>Contact Form</h2>
              <p className="lead">
              Fill out a quick form and we'll get back to you as soon as possible!
              </p>
            </div>
              <form id="form" onSubmit={(e)=> handleSubmit(e)}>
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="First and Last"
                    onChange={(e)=> handleInputChange(e,'name')}
                    value={this.state.name}
                  />
                </div>
                <div className="form-group">
                  <label >Organization</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder=""
                    onChange={(e)=> handleInputChange(e,'organization')}
                    value={this.state.organization}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control shadow-none"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    onChange={(e)=> handleInputChange(e,'email')}
                    value={this.state.email}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="parent-name">Phone Number</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder=""
                    onChange={(e)=> handleInputChange(e,'phone')}
                    value={this.state.phone}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="parent-name">Message</label>
                  <textarea 
                  rows="5" 
                  cols="80" 
                  id="TITLE"
                  className="form-control shadow-none"
                  placeholder=""
                  onChange={(e)=> handleInputChange(e,'message')}
                  value={this.state.message}
                  />
                </div>


                <button
                  type="submit"
                  className="btn btn-default"
                  id="studio-submit"
                >
                  Submit
                </button>
              </form>
          </div>
          }

          </div>
        </div>
      </Layout>
    );
  }
}

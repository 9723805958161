import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyAULCZlqPXBaU5P7N3gKljbLqzE0fikj4M",
  authDomain: "alex-website-3a40c.firebaseapp.com",
  projectId: "alex-website-3a40c",
  storageBucket: "alex-website-3a40c.appspot.com",
  messagingSenderId: "484963393592",
  appId: "1:484963393592:web:d6496cf2847b66f5b146cd"
};

firebase.initializeApp(firebaseConfig)

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {projectStorage, projectFirestore, timestamp};
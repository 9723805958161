import React from 'react';
import alexPiano from '../../assets/images/alex-1.png';
import alexPianoMobile from '../../assets/images/alex-piano-mobile.jpg';
import './HomeAbout.css';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
import welcomeFont from '../../assets/images/welcome-font.jpg';

const HomeAbout = (props) => (
  <>
    <img id="welcome-font" src={welcomeFont} alt="welcome" />
    <div className="container">
      <div className="row">
        <div className="col-lg-5 home-about-content">
          <MediaQuery minDeviceWidth={769}>
            <img id="alex-piano" src={alexPiano} alt="alex-piano" />
          </MediaQuery>
        </div>
        <div className="col-lg-7 home-about-content">
          <div className="home-description">
            <h2>Alexander Kostadinov</h2>
            <p>
              was born in Varna, Bulgaria and began his
              formative musical training at age four. He earned his bachelor’s
              and master’s degrees in music performance from Baylor University
              where he studied with Prof. Krassimira Jordan and Dr. Kae
              Hosoda-Ayer. During his time at Baylor University, he collaborated
              over 400 recitals with students, guest artists, and professors. He
              is currently a Doctoral student at the University of Maryland,
              where he studies under Prof. Rita Sloan. He is currently the
              pianist for the Maryland Opera Studio as well as the music
              minister at Hope Lutheran Church.
            </p>
            <div id="read-more">
              <NavLink to="/about">Read More</NavLink>
            </div>
          </div>
          <MediaQuery maxDeviceWidth={768}>
            <img id="alex-piano" src={alexPianoMobile} alt="alex-piano" />
          </MediaQuery>
          <div className="home-description">
            {/* <h2> Lorem Ipsum</h2> */}
            <p>
              "Alex is a spectacular musician and a wonderful person. His
              playing is sensitive and touches the soul." - Bonnie Wang
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default HomeAbout;
